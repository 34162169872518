/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  OutlinedInput,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DocumentReadResponse,
  AISession,
  QAListResponse,
  QAResponse,
  QARequestBody,
} from "models/api/response.types";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  AILanguage,
  AILanguages,
  maxAmountOfCharacters,
  terminal_status_names,
} from "utils/aiHelpers";
import {
  selectAILanguage,
  selectTextSelectionQuestion,
  setAILanguage,
  setTextSelectionQuestion,
} from "store/features/ai/slice";
import { selectUser } from "store/features/session/slice";
import { useUsers } from "api/userService";
import aiService, { useAiQA, useAISessions } from "api/aiService";
import clsx from "clsx";
import { useOrganizationUsage } from "api/organizationService";
import SendIcon from "@mui/icons-material/Send";
import { ClearOutlined } from "@mui/icons-material";
import LoadingOverlay from "components/helpers/LoadingOverlay";
import moment from "moment";
import { TypeAnimation } from "react-type-animation";
import AISessionDialog from "components/Dialogs/AISessionDialog";
import AISessionSelector from "components/helpers/AISessionSelector";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import handleAxiosError from "utils/handleAxiosAlert";
import OpenAiProvider from "components/helpers/OpenAiProvider";
import { APP_TITLE, PROD_HOSTNAME, companySettings } from "company-config";
import AIChatMessage from "components/helpers/AIChatMessage";
import { exportAIChatAsExcel } from "utils/aiChatExport";
import {
  getIsRightSideBarOpen,
  setRightSideBarOpen,
} from "store/features/documentViewer/slice";
import routePaths from "routes/routePaths";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Wrapper } from "./AiTab-styles";

const AiTab: React.FC<{
  document: DocumentReadResponse;
  browse?: boolean;
  onClose?: () => void;
}> = ({ document: docData, browse, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const smallLayout = useMediaQuery(theme.breakpoints.down("lg"));
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const language = useSelector(selectAILanguage);
  const currentUser = useSelector(selectUser);
  const isRightSideBarOpen = useSelector(getIsRightSideBarOpen);
  const textSelectionQuestion = useSelector(selectTextSelectionQuestion);
  const [selectedSession, setSelectedSession] = useState<AISession | undefined>(
    undefined
  );
  const isProd = window.location.hostname === PROD_HOSTNAME;
  const [aiModel, setAiModel] = useState<"gpt-3.5-turbo" | "gpt-4">(
    companySettings.defaultAIModel
  );
  const { organizationUsage, usageQueryKey } = useOrganizationUsage(
    docData.organization_id
  );
  const { aiSessions, createAISessionMutation, getCachedAISessionById } =
    useAISessions(docData.organization_id, docData.id);
  const { qaMessages, qaMessagesQueryKey } = useAiQA(selectedSession?.id);
  const { users } = useUsers(docData.organization_id);
  const [query, setQuery] = useState<string>("");
  const [useHistory, setUseHistory] = useState<boolean>(true);
  const [optionsCollapsed, setOptionsCollapsed] = useState<boolean>(true);
  const [restrictToContext, setRestrictToContext] = useState<boolean>(true);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  // loader on render for sessions
  const [sessionLoader, setSessionLoader] = useState<boolean>(true);
  // chat loader on render
  const [messagesLoader, setMessagesLoader] = useState<boolean>(true);
  // polling loading last message
  const [polling, setPolling] = useState<boolean>(false);
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
  const [openAiSessionDialog, setOpenAiSessionDialog] =
    useState<boolean>(false);
  const [sessionToUpdate, setSessionToUpdate] = useState<AISession | undefined>(
    undefined
  );
  const isContextLimitExceeded = docData?.meta?.text_character_count > 1000000;
  const showPlaceholder =
    (qaMessages?.length === 0 || !qaMessages) &&
    !messagesLoader &&
    !sessionLoader &&
    !limitExceeded &&
    !polling;

  const scrollToBottom = () => {
    const container = document.getElementById("chat-container");
    if (container) {
      container.scrollTo({
        top: container.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setPolling(false);
    if (aiSessions && !selectedSession) {
      const usersSessions = aiSessions
        .filter((session) => session.user_id === currentUser?.id)
        .sort((a, b) => b.created_at.localeCompare(a.created_at));
      if (usersSessions.length > 0) {
        setSelectedSession(usersSessions[0]);
      } else {
        setMessagesLoader(false);
      }
      setSessionLoader(false);
    }
  }, [aiSessions, selectedSession]);

  useEffect(() => {
    if (qaMessages) {
      if (messagesLoader) {
        setMessagesLoader(false);
        setTimeout(() => {
          scrollToBottom();
        }, 300);
      }
      if (
        qaMessages.some(
          (response) => !terminal_status_names.includes(response.task_status)
        )
      ) {
        setPolling(true);
      }
    }
  }, [qaMessages]);

  useEffect(() => {
    if (aiSessions && selectedSession) {
      const [currentSession] = getCachedAISessionById(selectedSession.id);
      setSelectedSession(currentSession);
    }
  }, [sessionToUpdate, docData]);

  // polling data , await to finish all tasks
  useEffect(() => {
    let intervalId: any;
    if (polling && qaMessages) {
      intervalId = setInterval(() => {
        if (
          !qaMessages.some(
            (response) => !terminal_status_names.includes(response.task_status)
          )
        ) {
          setPolling(false);
          scrollToBottom();
        }
        queryClient.invalidateQueries(qaMessagesQueryKey);
      }, 1000);
    }
    return () => {
      queryClient.invalidateQueries(usageQueryKey);
      clearInterval(intervalId);
    };
  }, [polling, qaMessages]);

  const defaultPostData: QARequestBody = useMemo(() => {
    // always use document for reference use_context = true
    return {
      query,
      language,
      restrict_to_context: restrictToContext,
      use_context: true,
      use_history: useHistory,
      chat_model: aiModel,
    };
  }, [query, language, restrictToContext, useHistory, aiModel]);

  const chooseLanguage = (lang: AILanguage) => {
    dispatch(setAILanguage(lang));
    setMenuAnchor(null);
  };

  const resetSession = () => {
    if (selectedSession) {
      aiService.resetAISession(selectedSession.id).then(() => {
        queryClient.setQueryData(qaMessagesQueryKey, []);
      });
    }
  };

  const postQuestion = (session: AISession, messageToUse?: QARequestBody) => {
    scrollToBottom();
    if (
      organizationUsage &&
      organizationUsage.usage_limits.ai_credits - 1 < 0
    ) {
      setLimitExceeded(true);
    } else {
      setQuery("");
      aiService
        .postQuestion(session.id, messageToUse || defaultPostData)
        .then(({ data }) => {
          // if any message in loading state
          if (
            data.some(
              (response) =>
                !terminal_status_names.includes(response.task_status)
            )
          ) {
            setPolling(true);
          }
          queryClient.invalidateQueries(qaMessagesQueryKey);
        })
        .catch((err) => {
          handleAxiosError(err as AxiosError, dispatch);
        });
    }
    if (textSelectionQuestion) {
      dispatch(setTextSelectionQuestion(undefined));
    }
  };

  const createDefaultSessionOnQuery = () => {
    createAISessionMutation.mutate(
      {
        document_ids: [docData.id],
        meta: {
          name: textSelectionQuestion?.query || query,
          color: undefined,
        },
        organization_id: docData.organization_id,
        user_id: currentUser?.id || 0,
        visibility: "private",
        is_multidoc: false,
      },
      {
        onSuccess: ({ data: newSession }) => {
          postQuestion(
            newSession,
            textSelectionQuestion
              ? {
                  query: textSelectionQuestion.query,
                  language: textSelectionQuestion.language,
                  restrict_to_context:
                    textSelectionQuestion.restrict_to_context,
                  use_context: textSelectionQuestion.use_context,
                  use_history: textSelectionQuestion.use_history,
                  chat_model: aiModel,
                }
              : undefined
          );
        },
      }
    );
  };

  // if doc viewer text question exist, post question
  // only when messages are visible
  useEffect(() => {
    if (
      textSelectionQuestion &&
      textSelectionQuestion.documentId === docData.id
    ) {
      if (smallLayout && !isRightSideBarOpen) {
        dispatch(setRightSideBarOpen(true));
      }
      if (
        !selectedSession &&
        aiSessions?.filter((session) => session.user_id === currentUser?.id)
          .length === 0
      ) {
        createDefaultSessionOnQuery();
      }
      if (selectedSession) {
        postQuestion(selectedSession, {
          query: textSelectionQuestion.query,
          language: textSelectionQuestion.language,
          restrict_to_context: textSelectionQuestion.restrict_to_context,
          use_context: textSelectionQuestion.use_context,
          use_history: textSelectionQuestion.use_history,
          chat_model: aiModel,
        });
      }
    }
  }, [textSelectionQuestion, selectedSession]);

  const messages: { [key: string]: QAListResponse } = useMemo(() => {
    if (qaMessages) {
      return qaMessages
        .sort((a, b) => a.created_at.localeCompare(b.created_at))
        .reduce((grouped: any, item) => {
          const date = moment(item.created_at);
          const key = date.format("YYYY-MM-DD");

          if (!grouped[key]) {
            grouped[key] = [];
          }

          grouped[key].push(item);

          return grouped;
        }, {});
    }

    return undefined;
  }, [qaMessages]);

  // need for response continuation
  const recentMessage = useMemo(() => {
    if (messages) {
      const recentKeyDate = Object.keys(messages).pop();
      if (recentKeyDate) {
        const recentMessages = messages[recentKeyDate];
        return recentMessages[recentMessages.length - 1];
      }
      return undefined;
    }
    return undefined;
  }, [messages]);

  const generateContinueResponse = () => {
    if (recentMessage && selectedSession) {
      postQuestion(selectedSession, {
        query: t("continue_response"),
        language,
        restrict_to_context: restrictToContext,
        use_context: true,
        use_history: true,
        chat_model: aiModel,
        meta: {
          search_query:
            recentMessage?.meta?.search_query || recentMessage.query,
        },
      });
    }
  };

  const showContinueButton = useMemo(() => {
    return recentMessage?.meta?.finish_reason === "length";
  }, [recentMessage]);

  return (
    <Wrapper>
      <OpenAiProvider />
      <Box
        className={clsx("main-container", {
          browse,
        })}
      >
        <Box className="header">
          {((isContextLimitExceeded && docData.status.embeddings !== 3) ||
            docData.status.embeddings === 3) && (
            <Tooltip
              enterDelay={500}
              placement="bottom-end"
              title={
                docData.status.embeddings === 3
                  ? t("text_analysis_difficulty")
                  : t("context_size_exceeded")
              }
            >
              <WarningAmberIcon color="warning" fontSize="small" />
            </Tooltip>
          )}
          <Box className="option-container">
            {qaMessages && qaMessages.length > 0 && (
              <>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  startIcon={<FileUploadOutlinedIcon fontSize="small" />}
                  onClick={() => {
                    exportAIChatAsExcel(
                      qaMessages,
                      selectedSession?.meta?.name,
                      users
                    );
                  }}
                  sx={{ marginLeft: "auto", paddingTop: 0, paddingBottom: 0 }}
                >
                  {t("export")}
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  startIcon={<RestartAltOutlinedIcon fontSize="small" />}
                  onClick={resetSession}
                >
                  {t("reset")}
                </Button>
              </>
            )}
            <Button
              color="primary"
              variant="text"
              size="small"
              sx={{
                whiteSpace: "nowrap",
              }}
              startIcon={<LanguageIcon fontSize="small" />}
              endIcon={<ExpandMoreIcon fontSize="small" />}
              onClick={(event) => {
                setMenuAnchor(event.currentTarget);
              }}
            >
              {language}
            </Button>
            {browse && (
              <IconButton
                size="small"
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box
          id="chat-container"
          sx={{
            ...((sessionLoader || messagesLoader) && {
              height: "100%",
            }),
            ...(showContinueButton && {
              paddingBottom: "3rem !important",
            }),
          }}
        >
          {sessionLoader || messagesLoader ? (
            <LoadingOverlay />
          ) : (
            <>
              {limitExceeded && (
                <Fade in>
                  <Box
                    className={clsx("placeholder", {
                      "full-width": limitExceeded,
                    })}
                  >
                    {limitExceeded && (
                      <>
                        <Typography variant="body2">
                          {t("credit_limit_exceeded_ai_table", {
                            appTitle: APP_TITLE,
                          })}
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => {
                            navigate(routePaths.workspaceBilling);
                          }}
                          sx={{ mt: 1 }}
                        >
                          {t("upgrade_credit_limit")}
                        </Button>
                      </>
                    )}
                  </Box>
                </Fade>
              )}
              {messages && Object.keys(messages).length > 0 && (
                <Box className="message-container">
                  {Object.entries(messages).map((messagesKey, index) => {
                    const currentMessages = messagesKey[1];
                    const currentDate = messagesKey[0];
                    const date = moment(currentDate).format("MMM D, YYYY");
                    return (
                      <>
                        <Box
                          className="date"
                          key={`${messagesKey[0]} + ${index}`}
                        >
                          <Divider>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              fontStyle="italic"
                            >
                              {date}
                            </Typography>
                          </Divider>
                        </Box>
                        <Box className="messages" key={messagesKey[0]}>
                          {currentMessages.map((message: QAResponse) => (
                            <AIChatMessage
                              organizationId={docData.organization_id}
                              key={message.id}
                              message={message}
                              isDocumentViewer={!browse}
                            />
                          ))}
                        </Box>
                      </>
                    );
                  })}
                </Box>
              )}
              {showPlaceholder && (
                <TypeAnimation
                  cursor={false}
                  sequence={[t("ask_question_instruction_ai_table")]}
                  className="placeholder full-width"
                  speed={75}
                />
              )}
            </>
          )}
        </Box>
        {showContinueButton && (
          <Button
            className="continue-button"
            size="medium"
            color="primary"
            variant="outlined"
            startIcon={<ArrowRightIcon fontSize="medium" />}
            onClick={generateContinueResponse}
          >
            {t("continue_generating")}
          </Button>
        )}
      </Box>
      <Box className="form-container">
        <Button
          className="collapse-button"
          fullWidth
          variant="text"
          color="primary"
          size="small"
          onClick={() => {
            setOptionsCollapsed(!optionsCollapsed);
          }}
        >
          <KeyboardArrowUpIcon
            className={clsx("option-icon", {
              opened: !optionsCollapsed,
            })}
            fontSize="medium"
          />
        </Button>
        <Collapse
          in={!optionsCollapsed}
          collapsedSize={selectedSession ? 40 : 0}
        >
          {aiSessions && aiSessions?.length > 0 && (
            <AISessionSelector
              organizationId={docData.organization_id}
              documentId={docData.id}
              selectedSession={selectedSession}
              setSelectedSession={setSelectedSession}
              sessionToUpdate={(session: AISession) => {
                setSessionToUpdate(session);
                setOpenAiSessionDialog(true);
              }}
            />
          )}
          <Button
            color="primary"
            variant="contained"
            size="small"
            fullWidth
            startIcon={<AddIcon fontSize="small" />}
            onClick={() => {
              setOpenAiSessionDialog(true);
            }}
          >
            {t("create_new_chat")}
          </Button>
          {!isProd && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                width: "100%",
              }}
            >
              <Typography className="body2" color="textSecondary">
                GPT-3.5
              </Typography>
              <Switch
                color="primary"
                size="small"
                checked={aiModel === "gpt-4"}
                onChange={() => {
                  setAiModel(aiModel === "gpt-4" ? "gpt-3.5-turbo" : "gpt-4");
                }}
              />
              <Typography className="body2" color="textSecondary">
                GPT-4
              </Typography>
            </Box>
          )}
          <FormControl className="history-container">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  className="primary"
                  checked={useHistory}
                  onChange={() => {
                    setUseHistory(!useHistory);
                  }}
                />
              }
              label={
                <Typography color="textSecondary" variant="body2">
                  {t("new_messages_related")}
                </Typography>
              }
            />
          </FormControl>
          <FormControl className="external-knowledge-container">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  className="primary"
                  checked={restrictToContext}
                  onChange={() => {
                    setRestrictToContext((cur) => !cur);
                  }}
                />
              }
              label={
                <Typography color="textSecondary" variant="body2">
                  {t("use_text_only")}
                </Typography>
              }
            />
          </FormControl>
        </Collapse>
        {organizationUsage &&
          organizationUsage?.usage_limits?.ai_credit_limit < 9999 &&
          !browse && (
            <Box className="usage-container">
              <Box className="credit-usage">
                <Typography variant="body2" color="textSecondary">
                  {t("available_message_credits", {
                    credits: `${organizationUsage.usage_limits.ai_credits} /
                  ${organizationUsage.usage_limits.ai_credit_limit}`,
                  })}
                </Typography>
                <Tooltip
                  enterDelay={500}
                  placement="top"
                  title={t("credit_cost_calculation")}
                >
                  <HelpOutlineIcon color="primary" fontSize="small" />
                </Tooltip>
              </Box>
              <LinearProgress
                value={
                  (organizationUsage.usage_limits.ai_credits * 100) /
                    organizationUsage.usage_limits.ai_credit_limit >
                  100
                    ? 100
                    : (organizationUsage.usage_limits.ai_credits * 100) /
                      organizationUsage.usage_limits.ai_credit_limit
                }
                variant="determinate"
                color="primary"
              />
            </Box>
          )}
        <Box className="question-container">
          <Box className="input-container">
            <Typography
              color="textSecondary"
              sx={{
                fontSize: "12px",
                ...(query.length === maxAmountOfCharacters && {
                  color: theme.red.main,
                }),
              }}
            >
              {t("characters")} {query.length}/{maxAmountOfCharacters}
            </Typography>
            <OutlinedInput
              color="primary"
              multiline
              size="small"
              maxRows={6}
              fullWidth
              value={query}
              onChange={(e: any) => {
                if (e.target.value.length < maxAmountOfCharacters) {
                  setQuery(e.target.value);
                } else {
                  const result = e.target.value.substr(0, 400);
                  setQuery(result);
                }
              }}
              placeholder={t("write_question")}
              sx={{
                padding: "0.3rem 0.5rem",
              }}
              endAdornment={
                <IconButton size="small" onClick={() => setQuery("")}>
                  <ClearOutlined fontSize="small" />
                </IconButton>
              }
              onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (
                  event.key === "Enter" &&
                  query.length > 0 &&
                  !polling &&
                  !limitExceeded
                ) {
                  event.preventDefault();
                  if (selectedSession) {
                    postQuestion(selectedSession);
                  } else {
                    createDefaultSessionOnQuery();
                  }
                }
              }}
            />
          </Box>
          <IconButton
            size="small"
            color="primary"
            disabled={query.length === 0 || polling || limitExceeded}
            onClick={() => {
              if (selectedSession) {
                postQuestion(selectedSession);
              } else {
                createDefaultSessionOnQuery();
              }
            }}
            sx={{
              marginTop: "1rem",
            }}
          >
            <SendIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={() => setMenuAnchor(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            maxHeight: 200,
          },
        }}
      >
        {AILanguages.map((lang: AILanguage) => (
          <MenuItem
            key={lang}
            onClick={() => {
              chooseLanguage(lang);
            }}
          >
            <Typography variant="body2">{lang}</Typography>
          </MenuItem>
        ))}
      </Menu>
      {openAiSessionDialog && (
        <AISessionDialog
          isMultiDoc={false}
          sessionToUpdate={sessionToUpdate}
          documentIds={[docData.id]}
          organizationId={docData.organization_id}
          setOpen={(open: boolean, newSession) => {
            if (sessionToUpdate) {
              setSessionToUpdate(undefined);
            }
            if (newSession) {
              setSelectedSession(newSession);
            }
            setOpenAiSessionDialog(open);
          }}
        />
      )}
    </Wrapper>
  );
};

export default AiTab;
